// Hide the toolbar or admin menu on small screens.
@media all and (max-width: 800px) {
  #toolbar, #admin-menu {
    display: none;
  }

  html {
    body.toolbar, body.admin-menu {
      padding-top: 0 !important;
      margin-top: 0 !important;
    }
  }
}

#toolbar, #admin-menu {
  box-shadow: none !important;
}

body.toolbar {
  padding-top: 3.6em;
}

.tabs {
  padding-left: 0;
  li {
    display: inline-block;
  }

  a {
    display: block;
    padding: .5em 1.5em;
    border: 1px solid black;
    border-bottom: 0;

    text-decoration: none;

    &:hover {
      text-decoration: underline;
      background: rgba(black, .2);
    }
  }
}