@keyframes shake {
  0%, 100% {
    tranform: none;
  }

  25% {
    transform: translateX(10px);
  }

  75% {
    transform: translateX(-10px);
  }
}