.c-quick-contact {
  @include clearfix;
  font-size: .9rem;
  h2 {
    @include visually-hidden;
  }

  p {
    margin: 0;
    float: left;
    width: 260px;
  }
}