.c-tooltip {
  position: absolute;

  font-size: .8rem;
  line-height: 1;
  padding: 0.5em 1em;

  background: $color-black;
  color: $color-white;

  display: inline-block;
  white-space: nowrap;
  border-radius: 4px;
  pointer-events: none;

  transition: 300ms ease-in-out;

  top: 100%;
  right: -0.5em;
  margin-top: 1.2em;

  opacity: 0;
  transform: translateY(1em);

  @include breakpoint($breakpoint-palm) {
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
  }
  &::before {
    $arrow-size: 6px;
    content: '';
    display: block;
    position: absolute;
    height: 0;
    width: 0;

    right: 1em;
    top: 0;
    margin-top: -$arrow-size * 2;

    border: $arrow-size solid $color-black;
    border-right-color: transparent;
    border-left-color: transparent;
    border-top-color: transparent;

    @include breakpoint($breakpoint-palm) {
      right: 50%;
      top: auto;
      bottom: -12px;
      transform: translateX(6px);
      border: 6px solid $color-black;
      border-right-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &.is-visible {
    @include visible-tooltip;
  }
}