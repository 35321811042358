img, media {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
}

.messages img {
  width: auto;
  display: inline;
}