.c-social-menu {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: inherit;
}

.c-social-menu__item {
  margin-left: 1em;
  transition: 300ms;
  svg { fill: currentcolor; }
  &:hover {
    color: $color-brand;
  }
}