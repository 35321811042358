.c-product-add-to-cart {
  @include clearfix;
  margin-bottom: 2em;
}

.c-product-add-to-cart__price {
  font-size: 26px;
  letter-spacing: 0;
  line-height: 1.23;
  font-weight: bold;
  float: left;
  padding-top: 8px;

  .commerce-price-savings-formatter-savings .price-amount {
    color: $color-white;
    background: $color-brand;
    padding: 0.2em 0.5em;
    &::before {
      content: "-";
    }
  }
}

.c-product-add-to-cart__form {
  float: right;

  @include breakpoint($breakpoint-portable) {
    float: none;
    clear: both;
    padding-top: 1em;
  }

  input[type="submit"] {
    color: $color-white;
    transition: 300ms;

    width: 100%;

    font-size: 13px;
    letter-spacing: 0.27em;
    line-height: 1.85;
    text-transform: uppercase;
    padding: 10px 20px;
    border: 0;
    background-color: $color-black;
    background-image: url(../gfx/add-to-cart.svg);
    background-position: right center;
    background-position: right 10px center;
    padding-right: 42px;
    cursor: pointer;

    &:hover {
      background-color: $color-brand;
    }
  }
}
