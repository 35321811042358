///////////////////////
// IMPORT FONTS HERE //
///////////////////////

$font-default: "aktiv-grotesk",sans-serif;
$font-fancy: 'lakesight';

@font-face {
    font-family: 'lakesight';
    src: url('../fonts/lakesight.woff2') format('woff2'),
         url('../fonts/lakesight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}