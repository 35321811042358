.l-product-grid {
  display: grid;
  gap: 0;
  @include breakpoint($breakpoint-lap-and-up) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include breakpoint($breakpoint-desk) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.view-products-on-sale {
  padding-top: 60px;

  .view-empty {
    padding-bottom: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
    a {
      color: $color-brand;
      text-decoration: underline;
    }
  }
}
