.c-newsletter {
  // @include clearfix;

  @include breakpoint($breakpoint-lap-and-up) {
    display: flex;
    flex-direction: row;
  }
  .c-block__title {
    font-size: 26px;
    letter-spacing: 0.07em;
    line-height: 1.31;
    text-transform: uppercase;
    font-weight: 300;

    @include breakpoint($breakpoint-palm) {
      font-size: 18px;
      text-align: center;
    }
  }

  form {
    padding-left: 3em;
    flex-grow: 1;
    margin-top: -5px;
    margin-left: auto;
    max-width: 700px;
    transition: 300ms;
    @include clearfix;

    @include breakpoint($breakpoint-palm) {
      margin-top: 2em;
      padding: 0;
    }

    &.is-error {
      animation: 300ms shake 2 ease-in-out;
    }

    &.is-submitted {
      opacity: .5;
    }
  }

  input[type=email], input[type=submit] {
    height: 52px;
    display: block;
    appearance: none;
    border: 0;
    width: 100%;
  }

  input[type=email] {
    font-size: 16px;
    color: $color-black;
    line-height: 1;
    background: $color-white;
    text-transform: lowercase;
    padding: 0 2em;
    text-align: center;
    @include breakpoint($breakpoint-lap-and-up) {
      text-align: right;
    }
  }

  input[type=submit] {
    background: $color-true-black;
    color: $color-white;

    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.07em;

    cursor: pointer;
  }

@include breakpoint($breakpoint-lap-and-up) {
  .form-item {
      width: calc(100% - 170px);
      float: left;
  }

  .form-actions {
    float: left;
    width: 170px;
  }
}

  label {
    @include visually-hidden;
  }
}