.c-brands {
  margin-top: 8em;
  background: $color-wild-sand;
  text-align: center;
  padding-bottom: 2em;


  .c-block__title {
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.26em;
    line-height: 2;
  }

  .c-block__title__inner {
    background: $color-white;
    display: inline-block;
    border: 1px solid currentcolor;
    transform: translateY(-50%);
    padding: .5em 3em;
  }

  .view-content {
    @include clearfix;
  }

  .views-row {
    width: 200px;
    vertical-align: middle;
    display: inline-block;
  }
}

.c-brand {
  opacity: 0.7;
}

.c-brand__inner {
  padding-top: 63%;
  margin: 10px 25px;
  background-position: center;
  background-repeat: no-repeat;

  background-size: contain;
  background-color: $color-wild-sand;
  backface-visibility: hidden;
  filter: grayscale(100%);
  background-blend-mode: multiply;


  img {
    // opacity: 0;
  }
}