.svg--add-to-cart-dims {
	width: 21px;
	height: 16px;
}

.svg--cart-dims {
	width: 21px;
	height: 16px;
}

.svg--delete-dims {
	width: 14px;
	height: 13px;
}

.svg--facebook-dims {
	width: 24px;
	height: 24px;
}

.svg--instagram-dims {
	width: 24px;
	height: 24px;
}

.svg--select-dims {
	width: 11px;
	height: 7px;
}

