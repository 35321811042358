.c-slider {
  position: relative;
  padding-top: 56%;
  user-select: none;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color-black, 0.2);
  }
}

.c-slider__item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-slider__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-slider__baseline {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: $color-white;
  letter-spacing: 0.06em;
  z-index: 10;
  font-weight: 300;
  line-height: 1;
  padding-bottom: .2em;
  overflow: hidden;

  font-size: 30px;

  @include breakpoint($breakpoint-lap-and-up) {
    font-size: 60px;
  }

  @include breakpoint($breakpoint-desk) {
    font-size: 100px;
  }
}

.c-slider__baseline__inner {
  display: block;
}

.c-slider__pager {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%) translateX(50%);

  display: none;



  @include breakpoint($breakpoint-desk) {
    display: block;
    right: -30px;
  }

  @include breakpoint($breakpoint-desk-mega) {
    right: -50px;
  }
}

