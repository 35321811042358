@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

body {
  background: $color-white;
  color: $color-black;
  animation: 500ms fade-in;
}

.object-fit {
  background-position: center center;
  background-size: cover;
}

::selection {
  background: $color-brand;
  color: $color-white;
}

.t-center {
  text-align: center;
}

.t-blackout {
  background: $color-black;
  color: $color-white;
}

.clearfix {
  @include clearfix;
}