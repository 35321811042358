.c-extra-images {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  > * {
    flex: 0 0 percentage(1/4);
    padding: 5px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
