.c-fancy-grid {
  position: relative;
  opacity: 0;
  transition: 300ms;
}

&.is-loaded {
  opacity: 1;
}

// 1229
// 710


$fancy-gutter: 1.5%;

.c-fancy-grid__item {
  &--narrow {
    width: 25% - ($fancy-gutter/4*3);
    padding-left: 11.1%;

    &.is-reversed {
      padding-left: 0;
      padding-right: 11.1%;
    }
  }

  &--normal {
    width: 25% - ($fancy-gutter/4*3);
  }

  &--wide {
    width: 50% - ($fancy-gutter/4*3);
  }

  @include breakpoint($breakpoint-palm) {
    &--narrow, &--normal, &--wide {
      padding: 0 !important;
      width: 49%;
    }
  }
}

.c-fancy-grid__gutter {
  width: $fancy-gutter;
}

.c-fancy-grid__item__half {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 60%;
}

.c-fancy-grid__item__half--right {
  left: auto;
  right: 0;
}