.messages,
.c-messages {
  padding: 10px;
  border: 2px solid #19C10A;
  background: rgba(#19C10A, 0.1);
  margin-bottom: 1em;
}

.messages--error,
.c-messages--error {
  border: 2px solid #E40015;
  background: rgba(#E40015, 0.1);
}