.c-body {
  * + * {
    margin-top: 1em;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  li {
    display: list-item;
  }

  h2 {
    @include beta;
  }

  * + h2 {
    margin-top: 2em;
  }

  a {
    text-decoration: underline;
    color: $color-brand;
    font-weight: bold;
    transition: 300ms;
  }

  a:hover {
    background: $color-brand;
    color: $color-white;
  }
}

.c-body--centered {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;

  h2 {
    max-width: none;
  }
}