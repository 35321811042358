.c-product-category-menu {
  @include clearfix;
  padding: 0 10px;
  position: relative;

  @include breakpoint($breakpoint-palm) {
    padding: 0;
    font-size: 14px;
  }

  @include breakpoint($breakpoint-lap-and-up) {
    font-size: 12px;
    // font-size: 16px;
  }

  @include breakpoint($breakpoint-desk) {
    font-size: 10px;
    // font-size: 16px;
  }

  @include breakpoint($breakpoint-desk-wide) {
    // font-size: 16px;
    font-size: 11px;
  }
}

@mixin portable-category-menu-item {
  @include breakpoint($breakpoint-portable) {
    padding: 0 2px;
    width: 50%;
    margin-bottom: 0.5em;

    &--first {
      width: 100%;
    }
  }

  @include breakpoint($breakpoint-palm) {
    text-transform: none;
    letter-spacing: 0;
  }
}

.c-product-category-menu__item {
  float: left;
  width: calc(100 / 7 * 1%);
  letter-spacing: 0.26em;
  padding: 1.56em 0.2em;

  @include breakpoint($breakpoint-desk-wide) {
    padding: 1.56em 0.5em;
  }

  text-align: center;

  text-transform: uppercase;
  @include portable-category-menu-item;

  a {
    line-height: 5.25em;
    height: 5.25em;
    display: block;
    transition: 200ms;

    background: $color-white;
    color: $color-black;
    border: 1px solid $color-black;
  }

  a.is-active,
  a:hover {
    color: $color-white;
    background-color: $color-brand;
  }
}

.c-hero {
  .c-product-category-menu {
    margin-top: 0.5em;
    @include breakpoint($breakpoint-lap-and-up) {
      margin-top: -4.13em;
    }
  }
  .c-product-category-menu__item {
    @include portable-category-menu-item;

    width: 25%;

    a {
      background: $color-black;
      color: $color-white;
    }

    a:hover {
      background-color: $color-brand;
    }
  }

  .c-product-category-menu__item--first {
    display: none;
  }
}
