.c-menu--main {
  @include breakpoint($breakpoint-portable) {
    display: none;
  }

  float: left;

  text-transform: uppercase;
  letter-spacing: 0.2em;
  line-height: 2;

  margin-top: 77px;

  font-size: 12px;

  @include breakpoint($breakpoint-lap-and-up) {
    margin-left: 1em;
  }

  @include breakpoint($breakpoint-desk-wide) {
    margin-left: 3em;
    font-size: 15px;
  }

  li {
    display: inline-block;
    margin: 0 1em;
  }

  .active,
  .active-trail {
    font-weight: bold;
  }

  a:hover {
    color: $color-brand;
  }

  a {
    transition: 300ms;
  }
}

.l-offcanvas {
  .c-menu--main {
    display: block;
    font-size: 14px;
  }

  li {
    text-align: center;
    display: block;
    float: none;
  }
}
