.c-fancy-title {
  text-align: center;
  max-width: 400px;

  font-family: $font-fancy;
  letter-spacing: 0.02em;
  line-height: 0.79;


  font-size: 30px;
  margin: 1.13em auto;

  @include breakpoint($breakpoint-lap-and-up) {
    font-size: 71px;
  }
}