.c-popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
  width: 800px;
  // border-radius: 0.4em;
  max-width: 90%;

  background: #fff;

  @include breakpoint($breakpoint-lap-and-up) {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.3em;
    font-weight: bold;
    margin-bottom: 1em;
    margin-right: 50px;
  }

  padding: 2em;

  p + p {
    margin-top: 1.5em;
  }
}

.c-popup__close {
  appearance: none;
  background: none;
  border: 0;
  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;
  color: #000;

  svg {
    display: block;
  }
}

.c-popup-overlay {
  background: rgba(#000, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
}

.c-popup[hidden],
.c-popup-overlay[hidden] {
  display: none;
}

.c-popup__actions {
  margin-top: 1.5em;
}
