.c-cart-button-wrapper {
  @include breakpoint($breakpoint-palm) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 500;
    display: flex;
  }
}

.c-cart-button {
  float: right;

  padding: 10px 25px;

  transition: 300ms;
  position: relative;

  &:hover {
    color: $color-brand;
    .c-tooltip {
      @include visible-tooltip;
    }
  }

  @include breakpoint($breakpoint-palm) {
    height: auto;
    background: $color-black;
    margin: 0;
    top: auto;
    float: none;
    padding: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: $color-white;
    width: 50%;

    @at-root body[class*="page-cart"] & {
      display: none;
    }
    @at-root body[class*="page-checkout"] & {
      display: none;
    }

    &:hover {
      background: $color-black;
      color: $color-white;
    }
  }
}

.c-cart-button--alt {
  background-color: $color-brand;
  font-weight: bold;
  color: $color-white;

  &:hover {
    background: $color-black;
    color: $color-white;
  }
}

.c-cart-button__label {
  font-size: 10px;
  letter-spacing: 0.31em;
  line-height: 1;
  text-transform: uppercase;

  @include breakpoint($breakpoint-palm) {
    font-size: 9px;
  }
}

.c-cart-button__icon {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  top: -2px;
  margin-left: 10px;
  fill: currentcolor;
}
