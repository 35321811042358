html {
  font-family: $font-default;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 1.5;
}

body {
}

p {
}

h1, .alpha {

}


@mixin beta {
  font-weight: bold;
  font-size: 1.73em;
  line-height: 1.23;
  max-width: 10em;
  margin-bottom: 2em;
  a {
    transition: 300ms color;
  }
  a:hover {
    color: $color-brand;
  }
}

.beta {
  @include beta;
}

h3, .gamma {
}

h4, .delta {

}

h5, .epsilon {

}

h6, .zeta {

}

// SLABTEXT
.kilo {

}