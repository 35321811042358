.c-menu-toggle {
  position: absolute;
  z-index: 10000;
  visibility: visible;
  display: block;

  top: 60px;
  right: 5px;
  padding: 10px;

  svg {
    display: block;
  }

  @include breakpoint($breakpoint-desk) {
    display: none;
  }
}

.c-menu-closer {
  position: absolute;
  top: 70px;
  right: 2em;
}