.c-article-date {
  text-align: right;
  font-weight: bold;
  font-style: italic;
  margin-top: 0.6em;
  font-size: .9em;
}

.l-col--right {
  .c-article-date {
    text-align: left;
  }
}