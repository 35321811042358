.payment-redirect-form,
.messages,
.user-register-form,
.user-pass,
.user-profile-form,
.commerce-checkout-form-account,
.checkout_completion_message,
.commerce-checkout-form-payment,
.commerce-checkout-form-review,
.commerce-checkout-form-checkout,
.commerce-checkout-form-shipping,
.view-commerce-cart-form {
  max-width: 600px;
  margin: 0 auto;

  table {
    width: 100%;
    border-collapse: collapse;

    img {
      max-width: 90px;
    }
  }

  fieldset {
    margin-bottom: 2em;
  }

  table, tr, th, td {
    border: 1px solid $color-border;
    border-left: 0;
    border-right: 0;
    vertical-align: middle;
  }

  th {
    display: none;
  }

  td {
    border: 0;
    margin: 0;
    padding: .5em 0;
  }

  .form-item {
    margin: 0;
  }

  padding-bottom: 3em;

  .views-field-commerce-unit-price,
  .c-field--name-product-id, .c-field--name-price {
    display: none;
  }

  .views-field-unit-price__number {
    display: none;
  }

  .views-field-title {
    font-weight: bold;
    font-size: 1.2em;
    padding-left: .5em;

    a:hover {
      text-decoration: underline;
    }
  }

  .views-field-field-main-image {
    width: 100px;
    a {
      display: block;
      border: 1px solid transparent;
    }
    a:hover {
      border-color: currentcolor;
    }
  }

  .views-field-total-price__number {
    font-weight: bold;
  }

  .views-field-edit-quantity, .views-field-remove-button, .views-field-commerce-total {
    text-align: right;
  }

  .line-item-summary {
    margin-top: 10px;
    font-size: 1.2em;
    letter-spacing: 0.05em;
  }

  .views-field-edit-quantity {
    width: 50px;
    padding-top: 10px;
  }

  input[type=number] {
    height: 30px;
    font-size: 16px;
    text-align: right;
  }

  .delete-line-item {
    margin-left: 5px;
    background: $color-brand;
    border: 0;
    appearance: none;
    background-image: url(../gfx/delete.svg);
    background-repeat: no-repeat;
    background-position: center;
    text-indent: 100px;
    white-space: nowrap;
    overflow: hidden;
    width: 30px;
    height: 30px;
    transition: 150ms all ease-in-out;

    &:hover {
      background-color: $color-black;
    }
  }

  .form-actions {
    margin-top: 1em;
    text-align: right;

    input {
      @include button;
      border: 0;
      // display: none;

      & + * {
        margin-left: 1em;
      }
    }

    #edit-submit {
      background: $color-border;

      &:hover {
        background: $color-black;
      }
    }

    #edit-checkout {
      background: $color-brand;
      color: $color-white;

      &:hover {
        background: darken($color-brand, 10%);
        color: $color-white;
      }
    }
  }
}


// checkout login

.form-wrapper__returning-customer,
.form-wrapper__guest-checkout {
  margin: 0;
  padding: 1em;
  font-size: 13px;

  input {
    width: 100%;
    max-width: 100%;
  }

  input[type=submit] {
    border: 0;
    background: $color-brand;
    color: $color-white;
    padding-left: 0;
    padding-right: 0;

    &:hover {
      color: $color-white;
      background: $color-black;
    }

    + * {
      display: block;
      margin-top: 5px;
    }
  }

  p {
    margin-bottom: 1em;
  }

  a {
    text-decoration: underline;
  }
}

.form-wrapper__returning-customer {
  @include grid-span(6, $gutter: 1);
}

.form-wrapper__guest-checkout {
  @include grid-span(6,7, $gutter: 1);
}


.checkout-buttons {
  text-align: center;
}

.button-operator {
  display: block;
  font-size: .8em;
  margin-top: .5em;
}

.checkout-continue {
  background: $color-brand;
  color: $color-white;

  &:hover {
    background: darken($color-brand, 10%);
    color: $color-white;
  }
}

.commerce_shipping {
  label {
    font-size: 0.9em;
  }
}

.commerce_shipping .description,
#payment-details {
  margin-top: 10px;
  border: 1px solid currentcolor;
  padding: 10px;
  font-size: .9em;
  font-weight: bold;

  * + * {
    margin-top: 0.5em;
  }
}

#payment-details {
  padding: 0;
  border: 0;
}

.checkout-back.form-submit {
  font-size: 0.7em;
}

.cart-empty-page,
.checkout-completion-message {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 3em;

  * + * {
    margin-top: 1em;
  }

  a {
    color: $color-brand;
    text-decoration: underline;
  }
}

.component-total {
  white-space: nowrap;
}

.payment-redirect-form {
  text-align: center;
  max-width: 32em;
  margin: 0 auto;

  input {
    margin-top: 1em;
  }
}