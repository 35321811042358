.c-product-teaser {
  position: relative;
  outline: 1px solid rgba($color-silver, 0.3);
  transition: 300ms;

  &::after {
    content: "";
    display: block;
    padding-top: calc(10 / 15 * 100%);
  }

  &.is-faded {
    opacity: 0.5;
  }

  &:hover {
    .c-product-teaser__overlay {
      opacity: 1;
    }
  }

  > img {
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-product-teaser__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba($color-black, 0.8);
  color: $color-white;
  text-align: center;

  transition: 300ms;
  opacity: 0;
}

.c-product-teaser__copy {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 10px;

  font-size: 16px;
  letter-spacing: 0.06em;
  line-height: 1.63;
}

.c-product-teaser__brand {
  text-transform: uppercase;
  font-weight: bold;
}

.c-product-teaser__cta {
  margin-top: 1em;
  text-transform: uppercase;
}

.c-product-teaser__price {
  table.commerce-price-savings-formatter-prices {
    width: 100%;
  }

  .commerce-price-savings-formatter-prices .price-amount {
    text-align: center;
    color: $color-white;
  }

  .commerce-price-savings-formatter-list .price-amount {
    font-size: 1em;
  }

  .commerce-price-savings-formatter-savings .price-amount {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 1em;
    font-weight: bold;
    background: $color-brand;
    color: $color-white;

    &::before {
      content: "-";
    }
  }
}
