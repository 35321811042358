.c-mini-disclaimer {
  @include clearfix;
  font-size: .9rem;
  margin-top: 1em;

  a {
    color: $color-brand;
    text-decoration: underline;
    font-weight: bold;
  }

  p {
    margin: 0;
    float: left;
    width: 260px;
  }
}