@mixin link-wrap {
  overflow: hidden;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

.c-link-wrap {
  @include link-wrap;
}