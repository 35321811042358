.c-site-logo {
  border-right: 1px solid $color-border;
  display: block;
  float: left;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 30px 35px;
  position: relative;
  z-index: 1;
  background: $color-white;

  width: 180px;

  @include breakpoint($breakpoint-lap-and-up) {
    width: 180px;

    img {
      position: relative;
      top: 10px;
    }
  }

  @include breakpoint($breakpoint-desk) {
    width: 180px;

    img {
      position: relative;
      top: 10px;
    }
  }

  @include breakpoint($breakpoint-desk-wide) {
    width: 220px;
    padding: 30px;
  }

  img {
    width: 100%;
    display: block;
    height: auto;
  }

  @include breakpoint($breakpoint-palm) {
    text-align: center;
    float: none;
  }
}
