.c-google-map {
  height: 0;
  position: relative;
  padding-top: 300px;
  background: $color-border;
}

.c-google-map__inner {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
