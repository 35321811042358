.c-instafeed {
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.c-instafeed__inner {
  display: grid;
  gap: 20px;
  // opacity: 0.5;

  grid-template-columns: repeat(3, 1fr);
}

.c-insta-post {
  outline: 1px solid rgba($color-silver, 0.3);
  display: block;
  position: relative;
  &::after {
    content: "";
    display: block;
    padding-top: calc(1 / 1 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.c-instafeed__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.c-instafeed__title {
  font-weight: bold;
  text-transform: uppercase;
}

.c-instafeed__btn {
  background: #000;
  color: #fff;

  display: flex;
  align-items: center;
  padding: 0.7em 1em;
  font-weight: bold;

  svg {
    margin-right: 0.5em;
  }
}
