$breakpoint-tiny-palm: max-width 400px;
$breakpoint-palm: max-width 719px;
$breakpoint-lap: 720px 1023px;
$breakpoint-lap-and-up: 720px;
$breakpoint-portable: max-width 1023px;
$breakpoint-desk: 1024px;
$breakpoint-desk-wide: 1200px;
$breakpoint-desk-mega: 1700px;


@include add-gutter(0);
@include add-grid(12);

@mixin l-constrained {
  width: 95%;

  @include breakpoint($breakpoint-lap-and-up) {
    width: calc(100% - 50px);
  }

  @include breakpoint($breakpoint-desk) {
    width: calc(100% - 125px);
  }

  @include breakpoint($breakpoint-desk-mega) {
    width: calc(100% - 200px);
  }
  margin: 0 auto;
  max-width: 1400px;
}