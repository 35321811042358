.c-brand-filter {
  text-align: center;
  margin-top: 0;
  margin-bottom: 2em;
}

.c-brand-filter__item {
  letter-spacing: 0.1em;
  display: inline-block;
  margin: 0.25em 1em;

  &:focus {
    outline: none;
  }

  &.is-active {
    border-bottom: 1px solid currentcolor;
  }
}
