$color-true-black: #000;
$color-black: #262324;
$color-white: #fff;

$color-border: #E2E2E2;

$color-brand: #DB77AD;

$color-silver: #B8B8B8;

$color-wild-sand: #F7F6F6;