@include breakpoint($breakpoint-palm) {
  body {
    padding-bottom: 40px;
  }
}

.l-header {
  @include l-constrained;
  border: 1px solid $color-border;
  border-top: 0;
}

.l-header__inner {
  @include clearfix;
  position: relative;
}

.l-header__top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 43px;
  border-bottom: 1px solid $color-border;
}

.l-content__inner {
  @include l-constrained;
  border: 1px solid $color-border;
  border-top: 0;
  border-bottom: 0;
}

.l-v-spaced {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.l-v-spaced-top {
  padding-top: 3rem;
}

.l-v-spaced-bottom {
  padding-bottom: 3rem;
}

.l-v-spaced-bottom-double {
  padding-bottom: 6rem;
}

.l-footer {
  position: relative;
}

.l-footer__inner {
  @include l-constrained;
  position: relative;
  & + & {
   border-top: 1px solid $color-white;
  }
}

.l-row {
  @include clearfix;
}


@include breakpoint($breakpoint-lap-and-up) {
  .l-col--right {
    @include grid-span(6, 7);
  }

  .l-col--left {
    @include grid-span(6, 1);
  }
}


.l-boxed {
  padding: 2em;
  @include breakpoint($breakpoint-lap-and-up) {
    padding: 2em;
  }
  @include breakpoint($breakpoint-desk) {
    padding: 5em;
  }
}

@include breakpoint($breakpoint-palm) {
  .l-col {
    padding-left: 2em;
    padding-right: 2em;
  }
}