.c-article-pager {
  a {
    font-size: 13px;
    color: $color-true-black;
    letter-spacing: 0.08em;
    line-height: 1.85;
    transition: 300ms;
  }

  a:hover {
    text-decoration: underline;
  }

  a:not(:last-child) {
    &::after {
      content: '|';
      margin: 0 0.3em 0 0.5em;
    }
  }
}