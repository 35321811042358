.c-image-link {
  display: block;
  overflow: hidden;
  img {
    transition: 500ms ease-in-out;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

.c-image-link--mini {
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
}