.c-target-filter {
  .view-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .views-row {
    margin: 3px;
    min-width: 100px;
    max-width: 90%;
  }

  button {
    appearance: none;
    display: inline-block;
    padding: 0.5em 1em;
    border: 1px solid currentColor;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    text-transform: uppercase;
    font-size: 12px;

    &.is-active {
      background: $color-brand;
      color: $color-white;
    }
  }
}

.c-target-filter + .c-target-filter {
  margin-top: 10px;
}
