.c-pager__item {
  display: block;
  width: 17px;
  height: 17px;
  text-indent: 30px;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid $color-silver;
  border-radius: 100%;
  transition: 300ms;
  backface-visibility:hidden;

  &:hover, &.is-active {
    box-shadow: 0 0 0 3px $color-brand;
    border-color: $color-brand;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &:focus {
    outline: none;
  }
}