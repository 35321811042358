select,
input[type=text],
input[type=email],
input[type=password] {
  height: 2.5em;
  font-size: 16px;
  padding: 0 0.5em;
  width: 100%;
  border: 1px solid $color-silver;
}

select {
  border-radius: 0;
  appearance: none;
  background: $color-white;
  background: url(../gfx/select.svg);
  background-repeat: no-repeat;
  background-position: right 1em center;
  padding-right: 3em;
}

label {
  font-size: 13px;
}

input[type=submit] {
  @include button;
}

.fieldset-legend {
  font-weight: bold;
  color: $color-black;
  font-size: 15px;
}

fieldset {
  border-color: $color-border;
  border-width: 1px;
}

.form-type-email {

}

.form-item {
  margin-bottom: 10px;
}