.c-page-title {
  text-align: center;
  background: $color-brand;
  color: $color-white;
  text-transform: uppercase;

  font-size: 22px;
  letter-spacing: 0.26em;
  line-height: 1.45;

  padding: 0.91em 0.91em;
}

.c-page-title--constrained {
  max-width: 600px;
  margin: 0 auto;
}