@mixin button {
  font-size: 13px;
  letter-spacing: 0.27em;
  line-height: 1.85;
  text-transform: uppercase;
  border: 1px solid currentcolor;
  display: inline-block;
  padding: 1em 2em;
  transition: 300ms;
  appearance: none;
  cursor: pointer;

  &:hover {
    background-color: $color-brand;
    border-color: $color-brand;
    color: $color-white;
  }
}