.c-button {
  @include button;
}

.c-button--mail {
  background: #d979ad;
  color: #fff;
  margin-top: 10px;
  width: fit-content;
}
