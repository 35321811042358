.l-offcanvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color-white;
  z-index: 18000;
  transform: translateX(-100%);
  transition: 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
  padding: 5em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  @include breakpoint($breakpoint-desk) {
   display: none;
  }

  &.is-open {
    transform: translateX(0%);
  }
}

.l-offcanvas__footer {
  margin-top: 3em;
}